export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC3XFsXuzo0nBBApqUa5IY2qzZuqFUk5HE",
    authDomain: "vrtaxi-in.firebaseapp.com",
    databaseURL: "https://vrtaxi-in-default-rtdb.firebaseio.com",
    projectId: "vrtaxi-in",
    storageBucket: "vrtaxi-in.appspot.com",
    messagingSenderId: "167014286548",
    appId: "1:167014286548:web:37c160263cb2b8360f6741"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
