export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyC3XFsXuzo0nBBApqUa5IY2qzZuqFUk5HE",
    authDomain: "vrtaxi-in.firebaseapp.com",
    databaseURL: "https://vrtaxi-in-default-rtdb.firebaseio.com",
    projectId: "vrtaxi-in",
    storageBucket: "vrtaxi-in.appspot.com",
    messagingSenderId: "167014286548",
    appId: "1:167014286548:web:37c160263cb2b8360f6741"
  }
};
