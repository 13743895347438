import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';
import firebase from 'firebase';


@Component({
  selector: 'app-round',
  templateUrl: './roundtrip.component.html',
  styleUrls: ['./roundtrip.component.css']
})
export class RoundComponent implements OnInit {

  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChildren('formRow') rows: any;

  sentOtpCode;
  rideDetails;
  settings = SITE_SETTINGS;
  showOtpError;
  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4'];
  enteredOtpCode;
  successfullySentOtpMsg = false;
  invalideOtp = false;
  display: any;
  showTimer = true;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  cars = [];
  selectedCar;
  origin;
  destination;
  requestRide;
  totalDistance;
  totalDuration;
  pickupPlace;
  dropPlace;
  journeyType = ['One Way', 'Round Trip'];
  selectedTripType = 'One Way';
  driverBata;
  baseFare;
  estimation;
  parseFloat: any;
  whatsupText;
  isBrowser: boolean;
  selectedDate;
  estimationDistance;
  bookingDetails;
  editable: boolean = true;
  minDate = new Date();
  submitted = false;
  tomorrow = new Date();
  originCityName;
  destinationCityName;
  routeDetails;
  selectedCarName;
  show = false;
  days;
  showOtpScreen;

  constructor(private _formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private taxiService: TaxiService,
    private http: HttpClient,
    private location: LocationStrategy,
    private dateAdapter: DateAdapter<Date>

  ) {
    this.dateAdapter.setLocale('en-GB');

    this.timer(1);
  }

  ngOnInit() {

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });

    if (this.settings.isCMS) {
      this.getTariff()
    } else {
      this.cars = CARS;
    }

    this.tomorrow.setDate(new Date().getDate() + 1);

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.firstFormGroup = this._formBuilder.group({
      tripType: ['Round Trip', Validators.required],
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: [new Date, Validators.required],
      email: ['']
    });

    this.firstFormGroup.get('pickupTime').setValue(moment(Date.now()).format('h:mm a'));
    this.form = this.toFormGroup(this.formInput);

  }

  get contactValidation() { return this.firstFormGroup.controls; }

  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.cars = tariff;
      }
    });
  }

  checkDate(start, end) {

    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
  }


  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;


        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
          (this.selectedCar) ? this.requestRide = true : this.requestRide = false;
        }

        this.zoom = 12;
      });
    });
  }

  selectedCarType(ev) {
    this.selectedCar = ev;
    this.selectedCarName = ev.carType
  }

  onResponses(event) {

    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

  requestAride() {

    this.submitted = true;

    if (this.firstFormGroup.get('tripType').value === 'One Way' && this.selectedCarName === this.cars[5].carType) {
      alert('Selected car not available for one way');
      return
    }

    if (this.firstFormGroup.get('tripType').value === 'Round Trip') {
      if (this.checkDate(this.firstFormGroup.get('pickupDate').value, this.firstFormGroup.get('returnDate').value)) {
        alert('Enter valid Return Date');
        return
      }
    }

    if (this.firstFormGroup.invalid) {
      return;
    }

    if (!this.origin || !this.destination) {
      alert('Enter valid Pickup or Drop Address');
      return
    }

    if (!this.selectedCar) {
      alert('Select preffered vechicle type');
      return
    }

    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    if (this.settings.OTP) {
      this.showOtpScreen = true;
      this.sendOtp();
      this.stepper.next();
      this.scrollToTop();
    } else {
      this.getEstimation();
      this.stepper.next();
      this.scrollToTop();
    }
  }


  scrollToTop() {
    const container = document.querySelector('#booking');
    container.scrollTop = 0;
  }

  getEstimation() {


    let startDate = moment(moment(this.rideDetails.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
    let returnDate = moment(moment(this.rideDetails.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
    this.days = returnDate.diff(startDate, 'days') + 1;

    (this.rideDetails.totalDistance * 2 <= 250) ? this.rideDetails.totalDistance = this.days * 250 : (this.rideDetails.totalDistance * 2 > 250 * this.days) ? this.rideDetails.totalDistance = this.rideDetails.totalDistance * 2 : this.rideDetails.totalDistance = 250 * this.days;

    this.estimation = {
      tripEstimation: this.rideDetails.totalDistance * +this.selectedCar.roundtrip + +this.selectedCar.roundTripDriverFee * this.days,
      ratePerKM: +this.selectedCar.roundtrip,
      driverBatta: +this.selectedCar.roundTripDriverFee * this.days,
      baseFare: +this.rideDetails.totalDistance * +this.selectedCar.roundtrip,
      tripType: this.rideDetails.tripType,
      carType: this.selectedCar.carType,
      distance: this.rideDetails.totalDistance
    }

    this.bookNow();

  }



  bookNow() {
    this.bookingDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails,
      ...this.estimation,
      bookingId: Math.floor((Math.random() * 1000) + 1)
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('DD/MM/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'baseFare': this.bookingDetails.baseFare,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('DD/MM/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Website Enquiry From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      logoURL: this.settings.logoURL,
      'days': (this.bookingDetails.tripType === 'Round Trip') ? this.days : 'NA'
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let smsMessage = `
    Website Enquiry \r\n
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`;

    let telegramBody = {
      message: smsMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    this.stepper.next();
    this.scrollToTop();
    this.show = true;
  }


  confirm() {

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'baseFare': this.bookingDetails.baseFare,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Confirmed Booking From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      'sendCustomerEmail': true,
      'days': (this.bookingDetails.tripType === 'Round Trip') ? this.days : 'NA'
    }

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate}`

    let smsMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`


    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `
    Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${bookingDetails.siteName}. Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNo}.
Happy Journey.
    `

    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    let telegramBody = {
      message: smsMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    if (this.settings.isSMSEnabled) {
      this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));
    }

    if (this.settings.isDatabaseEnable) {
      firebase.database().ref(`websiteBookings/${bookingDetails.bookingId}`).update(bookingDetails).then(res => {
        console.log('Booking Updated Successfully');
      })
    }


    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");
    this.stepper.next();
    this.scrollToTop();
  }

  home() {
    window.open(`https://${this.settings.websiteAddress}`, '_self');
  }


  sendOtp(resend?) {
    if (resend) {
      this.showTimer = true;
      this.timer(1);
    }
    this.sentOtpCode = Math.floor(1000 + Math.random() * 9000);
    let otpBody = {
      "variables_values": `${this.sentOtpCode}`,
      "route": "otp",
      "numbers": this.rideDetails.phone,
      'smsKey': this.settings.smsKey
    }

    if (this.settings.OTP) {
      this.http.post(this.settings.OTPApi, otpBody).subscribe(res => {
        this.successfullySentOtpMsg = true;
        console.log(res);
      }, (e => {
        this.showOtpError;
      }));
    }
  }

  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    this.invalideOtp = false;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  verifyOtp() {
    this.successfullySentOtpMsg = false;
    const { input1, input2, input3, input4 } = this.form.value;
    this.enteredOtpCode = `${input1}${input2}${input3}${input4}`;
    if (this.sentOtpCode === +this.enteredOtpCode) {
      this.getEstimation();
    } else {
      this.invalideOtp = true;
    }
  }

  timer(minute) {
    let seconds: number = minute * 30;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 30;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.showTimer = false;
      }
    }, 1000);
  }
}
